body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*slideHeader*/
.divOpacity{
  background: rgba(11, 11, 11, 0.43);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
  width: 100%;
  min-height: 85vh;
  padding: 0 0 0 10%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.divText{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
}
.slideH1{
  color: #FFFFFF;
  font-size: 4rem;
  width: 60%;
  text-transform: uppercase;
}
.slideP{
  color: #FFFFFF;
  font-size: 18px;
  width: 70%;
  max-height: 105px;
  overflow: hidden;
  z-index: 11;
}
.slideP p{
  color: #fff!important;
}
.h1Span{
  color: #fd5d14;
}

/*slideOurCategory*/
.slidesTexts{
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: self-start;
  transition: .2s ease-out;
}
.slidesTextsChild{
  background: linear-gradient(to bottom,transparent,#000);
  padding: 0 10px 60px 20px;
  width: 100%;
}
.slidesTexts h5:before{
  content:'';
  background-color: #fd5d14;
  width: 60px;
  height: 3px;
  display: block;
  margin-bottom: 5px;
}
.ourCategorySlideH5{
  text-transform: uppercase;
  transform: translateY(20px) translateZ(0);
  transition: all .3s cubic-bezier(.77,0,.175,1);
}
.slidesTexts:hover{
  transform: scale(1.08, 1.08);
}
.slidesTexts:hover .ourCategorySlideH5{
  transform: translateY(0) ;
}
.ourCategorySlideP{
  opacity: 0;
  transform: translateY(20px) translateZ(0);
  transition: all .6s cubic-bezier(.77,0,.175,1);
}
.slidesTexts:hover .ourCategorySlideP{
  transform: translateY(0);
  opacity: 1;
}

/*slideBlock*/
.sliderBlockContainer{
  position: relative;
  padding: 0 10px;
}
.sliderBlockContainerChild{
  position: relative;
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0 0 0 0.05);
  transition: 0.3s;
  background: #FFFFFF;
}
.sliderBlockContainer:hover sliderBlockContainerChild{
  box-shadow: 0px 10px 20px rgba(0 0 0 0.1);
}
.slidesOurBlockImgBox{
  overflow: hidden;
}
.slidesOurBlockImg{
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 250px;
  transition: .5s ease;
}
.sliderBlockContainerChild:hover .slidesOurBlockImg{
  transform: scale(1.08, 1.08);
}
/*.post-video{*/
/*  position: absolute;*/
/*  top: 30%;*/
/*  left: 0;*/
/*  right: 0;*/
/*  transform: translate(0%, -50%);*/
/*  justify-content: center;*/
/*  display: flex;*/
/*}*/
.post-date{
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1;
}
.post-data-a{
  display: inline-block;
  padding: 8px 15px;
  line-height: 10px;
  font-size: 14px;
  background-color: #fd5d14;
  color: #fff;
  float: right;
  position: relative;
}
.cats{
  position: absolute;
  margin-top: -20px;
  margin-left: 20px;
  background: #00a5e0;
  margin-bottom: 15px;
  padding: 5px 10px;
  width: fit-content;
  z-index: 1;

}
.cats-office{
  color: #fff;
  font-size: 16px;
  position: relative;
}
/*.cats-office:after{*/
/*  content:'/';*/
/*  margin: 0 5px;*/
/*  position: relative;*/
/*  top: 1px;*/
/*}*/
.cats-rent{
  color: #fff;
  font-size: 16px;
  position: relative;
}
.post-title-parent{
  padding: 20px;
  height: 250px;
  overflow: hidden;
}
.post-title{
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.blog-footer-meta{
  border-top: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.post-author{
  display: flex;
  align-items: center;
}
.author-img{
  margin-right: 15px;
}
.link-btn{
  text-decoration: underline;
  text-transform: uppercase;
}
.slick-prev{
  z-index: 10;
}
@media (max-width: 1462px){
  .slick-prev{
    z-index: 0;
  }
}
.slick-prev:before, .slick-next:before{
  color:#fd5d14 !important;
  font-size: 40px !important;
}
/*footer*/
.phoneContact li{
  font-size: 14px;
  margin-bottom: 10px;
}
/*BlogPagesCom*/
.nameOfHead{
  font-weight: 600;
  font-size: 22px;
}
@media (max-width: 991px) {
  .contCardText{
    margin-top: 40px;
  }
}
.headStaffCard{
  transition: 1s ease-out;
  box-shadow: 0 2px 4px rgba(124, 138, 151, 0.3);
}
.headStaffCard:last-child{
  margin-bottom: 50px;
}
.headStaffCard:hover{
  box-shadow: 0 4px 16px #808b96;
}
.cardLine{
  border-top: 2px solid #eee;
  margin-top: 25px;
  padding: 20px 0;
}
.cardDetail{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.cardParagh{
  font-size: 16px;
  color: #7c8a97;
  margin: 0;
  font-family: sans-serif;
}
.cardSpan{
  color: #111111;
  margin-left: 15px;
  font-size: 16px;
}
/*titlePage*/
.titlePage{
  text-transform: lowercase;
}
.textDecorate{
  border-bottom: 2px solid #fd5d14;
  background: #fff;
  padding: 0 20px;
}
.pageImgPar{
  overflow: hidden;
  width: 100%;
  height: 450px;
  margin-bottom: 20px;
}
.pageImg{
  width: 100%;
  height: 450px;
  object-fit: cover;
  object-position: center;
  transition: 0.5s ease;
}
.pageImg:hover{
  transform: scale(1.08, 1.08);
}
.post-data-blogs-a{
  display: inline-block;
  padding: 15px;
  line-height: 10px;
  font-size: 14px;
  position: relative;
  background-color: #fd5d14;
  color: #fff;
  float: right;
}
.post-data-blogs-a::after{
  position:absolute;
  content: '';
  display: block;
  right: 100%;
  top: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid;
  border-left-color: #fd5d14;
  border-right-color: #fd5d14;
}
/* blogsPage */
.blogsImagePar{
  width: 100%;
  height: 250px;
  overflow: hidden;
}
.blogsImage{
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
  border-radius: 0;
  transition: 0.5s ease;
}
.blogsCard:hover .blogsImage{
  transform: scale(1.08, 1.08);
}
.blogsBody{
  width: 100%;
  height: 250px;
  overflow: hidden;
  margin-top: 20px;
}
.blogsCard{
  box-shadow: 0 1px 2px #898f96;
  transition: 0.3s ease-in;
  border: none;
  margin-bottom: 30px;
  border-radius: 0;
  position: relative;
}
.blogsCard:hover{
  box-shadow: 0 2px 8px #7c8a97;
}
.blogCats{
  position: absolute;
  top: 250px;
  margin-top: -20px;
  margin-left: 20px;
  background: #00a5e0;
  margin-bottom: 15px;
  padding: 5px 10px;
  width: fit-content;
  z-index: 1;
}

